<template>
  <div class="register">
    <Header />
    <b-container class="register-container">
      <b-row class="justify-content-center">
        <div>
          <b-card-group deck>
            <b-card>
              <div class="row">
                <div class="col-lg-5 col-md-6 my-auto">
                  <div class="register-form">
                    <h1>Register</h1>
                    <div class="error my-2" v-if="emtyForm">
                      <p>Form harus dilengkapi.</p>
                    </div>
                    <div class="error my-2" v-if="refCodeInvalid">
                      <p>Terjadi kesalahan, cek kembali kode referral.</p>
                    </div>
                    <template>
                      <div>
                        <form
                          action=""
                          method="POST"
                          @submit.prevent="handleSubmit"
                        >

                          <h3 class="login-label">User ID</h3>
                          <vs-input
                            id="username"
                            type="text"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            v-model="username"
                            required
                            @keyup="handleInputOnKeyup" 
                          />
                          <div class="error my-2" v-if="phoneIsExist">
                            <p>User id sudah terdaftar.</p>
                          </div>
                          <div class="error my-2" v-if="phoneAndEmailIsExist">
                            <p>Email dan user id sudah terdaftar.</p>
                          </div>

                          <!-- <h3 class="login-label">Email</h3> -->
                          <!-- <vs-input
                            id="email"
                            type="email"
                            required
                            class="mb-2 mr-sm-2 mb-sm-0"
                            v-model="email"
                          /> -->
                          <!-- <div class="error my-2" v-if="!$v.username.username">
                            <p>Format email belum sesuai</p>
                          </div> -->
                          <!-- <div class="error my-2" v-if="emailIsExist">
                            <p>Email sudah terdaftar.</p>
                          </div> -->

                          <h3 class="login-label">Password</h3>
                          <div class="my-2">
                            <small
                              ><i
                                >*password minimal 8 karakter, mengandung
                                minimal 1 huruf kapital dan 1 angka</i
                              >
                            </small>
                          </div>
                          <div class="error my-2" v-if="passwordFormatInvalid">
                            <p>Format password tidak sesuai.</p>
                          </div>

                          <div class="row">
                            <div class="col-9">
                              <vs-input
                                v-show="!showPass"
                                id="password"
                                type="password"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password"
                                required
                              />
                              <vs-input
                                v-show="showPass"
                                id="password"
                                type="text"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password"
                                required
                              />
                            </div>
                            <div class="col-3">
                              <b-button
                                @click="showPass = !showPass"
                                block
                                class="btn-show-password py-2"
                              >
                                <div v-show="!showPass">
                                  <b-icon icon="eye"></b-icon>
                                </div>
                                <div v-show="showPass">
                                  <b-icon icon="eye-slash"></b-icon>
                                </div>
                              </b-button>
                            </div>
                          </div>

                          <div class="error my-2" v-if="!$v.password.minLength">
                            <p>
                              Password minimal
                              {{ $v.password.$params.minLength.min }} karakter.
                            </p>
                          </div>

                          <h3 class="login-label">Konfirmasi Password</h3>

                          <div class="row">
                            <div class="col-9">
                              <vs-input
                                v-show="!showConfirmationPass"
                                id="password_confirmation"
                                type="password"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password_confirmation"
                                required
                              />
                              <vs-input
                                v-show="showConfirmationPass"
                                id="password"
                                type="text"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password_confirmation"
                                required
                              />
                            </div>
                            <div class="col-3">
                              <b-button
                                @click="
                                  showConfirmationPass = !showConfirmationPass
                                "
                                block
                                class="btn-show-password py-2"
                              >
                                <div v-show="!showConfirmationPass">
                                  <b-icon icon="eye"></b-icon>
                                </div>
                                <div v-show="showConfirmationPass">
                                  <b-icon icon="eye-slash"></b-icon>
                                </div>
                              </b-button>
                            </div>
                          </div>
                          <div
                            class="error my-2"
                            v-if="!$v.password_confirmation.sameAsPassword"
                          >
                            <p>Password yang Anda masukkan tidak sama.</p>
                          </div>
                          <div class="mb-4"></div>
                          <div class="row">
                            <div
                              class="col-lg-12 col-md-6 d-flex flex-row-reverse"
                            >
                              <div class="mx-2 terms">
                                <div class="mx-2 ml-n1">
                                  <p>
                                    Saya telah membaca, memahami, dan menyetujui
                                    <b-link
                                      href="#"
                                      class="text-danger text-decoration-none"
                                      target="_blank"
                                      >Syarat dan Ketentuan.</b-link
                                    >
                                  </p>
                                </div>
                              </div>
                              <div class="align-items-center mt-1">
                                <vs-checkbox
                                  v-model="checked"
                                  color="secondary"
                                >
                                </vs-checkbox>
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <div class="col-lg-12 col-md-6">
                              <b-button
                                block
                                type="submit"
                                :disabled="!checked || loading"
                                @click="handleSubmit()"
                                class="btn-tertiary py-2"
                              >
                                <div v-if="loading">
                                  <div
                                    class="spinner-border spinner-border-sm"
                                  ></div>
                                  Loading
                                </div>
                                <div v-else>Daftar</div>
                              </b-button>
                            </div>
                          </div>
                        </form>
                        <b-row
                          class="
                            justify-content-md-center
                            dont-have-account-row
                          "
                        >
                          <div class="dont-have-account">
                            <span
                              >Sudah punya akun?
                              <b-link class="login-link" to="/">Masuk</b-link>
                            </span>
                          </div>
                        </b-row>
                        <b-row class="justify-content-end text-center licensed">
                          <div class="col-lg-6 col-md-6">
                            <div class="license-text">
                              <span
                                >Berizin dan <br />
                                Diawasi oleh</span
                              >
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="ojk-logo text-center text-lg-left">
                              <img
                                class="ojk-logo-image"
                                src="@/assets/img/login/ojk-logo.webp"
                                alt=""
                              />
                            </div>
                          </div>
                        </b-row>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="col-lg-7 col-md-6">
                  <div class="register-img">
                    <img
                      src="@/assets/img/register/register-img.webp"
                      alt="register-img"
                      width="103%"
                    />
                  </div>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
    </b-container>
    <Footer4 />
    <b-modal id="modal-failed-register" v-model="failedShow" size="md" class="modal-failed-register" centered hide-footer>
      <div class="d-block text-center">
        <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
        <h1 class="header-modal p-4 my-2 text-dark">
          UserID tidak ditemukan
        </h1>
        <p>Silahkan hubungi customer service</p>
        <!-- <p>{{errorMessage}}</p> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer4 from "@/components/partials/footer/Footer4.vue";

import axios from "axios";
import { sameAs, minLength } from "vuelidate/lib/validators";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "Register",
  metaInfo: {
    title: "Registrasi | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer4,
  },
  data() {
    return {
      // email: "",
      username: "",
      password: "",
      password_confirmation: "",
      sales_id: "",
      s21ClientData: {},
      // successShow: false,
      emailIsExist: false,
      phoneIsExist: false,
      phoneAndEmailIsExist: false,
      passwordFormatInvalid: false,
      emtyForm: false,
      refCodeInvalid: false,
      loading: false,
      processing: false,
      showPass: false,
      showConfirmationPass: false,
      checked: false,
      failedShow: false,
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
    }),
  },
  created() {
  },
  validations: {
    // username: {
    //   email,
    // },
    password: {
      minLength: minLength(8),
    },
    password_confirmation: {
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    handleInputOnKeyup() {
      this.username = this.username.toUpperCase()
    },
    async handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        try {

          let response = await instance({
            method: "GET",
            url: `${this.app_url}s21client?clientId=${this.username}`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          this.s21ClientData = response.data.data.ClientID;
          this.s21ClientEmail = response.data.data.Email;
          localStorage.setItem("checkEmail", this.s21ClientEmail);
          localStorage.setItem("registration_temp", JSON.stringify(response.data.data));

          if (this.s21ClientData) {
            let data = new FormData();

            data.append("email", this.s21ClientEmail);
            data.append("name", this.s21ClientEmail);
            data.append("client_id", this.username);
            data.append("password", this.password);
            data.append("password_confirmation", this.password_confirmation);
            data.append("role", "3");
            data.append("type", "2");

            let axiosConfig = {
              headers: {
                "Content-Type": "application/json",
              },
            };

            instance
              .post(`${this.app_url}2822`, data, axiosConfig) //staging

              .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                this.loading = !false;

                localStorage.setItem("checkUserId", this.username);
                localStorage.setItem("checkPassword", this.password);

                  window.location.href = "/verification";
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                // } else {
                this.processing = false;
                //   this.emailIsExist = false;
                //   this.phoneIsExist = false;
                //   this.passwordFormatInvalid = false;
                //   this.phoneAndEmailIsExist = false;
                //   this.emtyForm = false;
                //   this.refCodeInvalid = false;
                // }
              });
          } else {
            alert('ClientID not found')
          }

        } catch (error) {
          this.failedShow = true;
          this.processing = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  background: #f3f4f6;
}
.register-container {
  padding-top: 140px;
  padding-bottom: 80px;
}

.con-vs-checkbox {
  justify-content: start;
  margin: 0;
}

.register-form {
  padding: 48px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #4b5563;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f566b;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #111827;
}

a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #e60012;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs-con-input-label {
  width: 100%;
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e;
  }
}

.login-label {
  padding-top: 16px;
}

.forgot-password-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #6b7280;
  text-decoration: none;
}

.remember-me {
  padding-top: 16px;
  padding-bottom: 16px;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 0px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.dont-have-account {
  padding-top: 28px;
}

.login-link {
  font-family: $inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #e60012;
  text-decoration: none;
}

.licensed {
  padding-top: 30px;
}

.license-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1f2937;
}

.ojk-logo-image {
  width: 120px;
}

.modal-register {
  z-index: 99999;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: black;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px;
  }
  .login-container {
    padding-bottom: 60px;
  }

  .back-nav {
    padding: 0px 240px 20px 0px;
  }
  .card-body {
    width: 100%;
  }

  .register-img {
    display: none;
  }
  .forgot-password-link {
    font-size: 10px;
  }
  .dont-have-account-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .licensed {
    text-align: center;
  }
  .ojk-logo-image {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .login-container {
    padding-bottom: 300px;
  }
}
</style>
